.no_passes {
  font-weight: 300;
  font-size: 1.6em;
  text-align: center;
}

.loading {
  padding-top: 5em;
}

.loading>* {
  margin: 0 auto;
}

.not_mobile .pass_list_container {
  display: table;
  min-width: 600px;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.pass {
  display: table-row;
}

.pass_image div.centered_container {
  display: table;
}

.pass_image .pass_image_block {
  width: 300px;
  height: 200px;
  background: url('/public/images/pass-image-90-opacity.png');
  background-size: cover;
}

.mobile .pass_image {
  margin-block: 1rem;
}

.mobile .mobile_pass_separator {
  text-align: center;
  font-size: 3rem;
}

.mobile .pass_image .pass_image_block {
  width: 100%;
  padding-top: calc(50% - 70px); /* aspect ratio 3:2 "hack" - https://www.w3schools.com/howto/howto_css_aspect_ratio.asp  -- needded to subtract 70px which is 1/2 of 140px below -- TODO: need to figure out why */
}

.centered_item--cart_buttons {
  height: 140px;
}

.pass_image .pass_image_block.pass_image_block-unavailable {
  background: #cdcbc9;
}

.pass_image div.centered_container div.centered_item {
  display: table-cell;
  text-align: center;
  vertical-align: bottom; 
  padding-bottom: 2rem;
  /* middle; */
}

.pass_cart_button {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

.pass_cart_button .pass_cart_button_added_to_cart {
  display: inline-block;
}

.pass_cart_button .pass_cart_button_remove_from_cart {
  display: none;
}

@media (hover: hover) {
  .pass_cart_button:hover .pass_cart_button_added_to_cart {
    display: none;
  }

  .pass_cart_button:hover .pass_cart_button_remove_from_cart {
    display: inline;
  }
}

.cart_counter {
  margin-top: 1em;
  font-size: 1.0rem;
  text-transform: uppercase;
}

.cart_counter_inner {
  display: inline-block;
  background-color: white;
}

.cart_counter .button {
  display: inline-block;
  background: white;
  color: black;
  width: 1em;
  padding: 0.25em;
  text-align: center;
  cursor: pointer;
}

.cart_counter .buy_a_pass_counter_button,
.cart_counter .buy_a_pass_counter_button:hover {
  font-weight: 500;
  width: 1.5em;
  padding: 0.25em 0;
  border: 0 !important;
  text-align: center;
  cursor: pointer;
}

@media (hover: hover) {
  .cart_counter .button:hover {
    background: black;
    color: white;
  }
}

.cart_counter .amount {
  display: inline-block;
  font-weight: 300;
  color: black;
  min-width: 2em;
  padding: 0.25em 0;
}

.pass_details {
  display: table-cell;
  vertical-align: top;
  padding-left: 2em;
}

.mobile .pass_details {
  padding-left: unset;
}

.pass_separator {
  display: table-cell;
  column-span: all;
  height: 2em;
}

.pass_details .title {
  font-size: 2rem;
}

.pass_details .sub_title {
  margin-top: 0.5em;
}

.price {
  font-size: 2rem;
  font-weight: bold;
  color: #dca;
  padding-right: 0.5em;
}

.price_details {
  font-size: 1.2rem;
  font-weight: 300;
  color: #dca;
  text-transform: uppercase;
}

.pass_description {
  font-weight: 500;
  font-size: 1.15rem;
  margin-top: 1em;
  text-transform: uppercase;
}