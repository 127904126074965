.cart_container {
  background: linear-gradient(to right, #e2c9a9 0%, #caa879 65%, #d5b78e 100%);
}

.not_mobile .cart_container {
  min-width: 800px;
  max-width: 800px;
}

.cart_table_container {
  padding: 2em;
}

.checkoutcart_header {
  margin-bottom: 1em;
}

.checkoutcart_header h3 {
  font-weight: 500;
  color: black;
  margin: 0;
  text-transform: uppercase;
}

.cart_container .cart_table .cart_item > td {
  font-size: 1.0rem;
}

.cart_container .cart_table .cart_total > td {
  font-size: 1.2rem;
}
