.bob_review_details__field {
  color: black;
  font-size: 1.2rem;
  font-weight: 300;
}

.bob_review_details hr {
  width: 100%;
  border: 0;
  height: 1px;
  background: black;
  margin: 2em 0;
}