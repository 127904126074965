.order_summary_container {
  background: #dedede;
  height: fit-content;
}

.not_mobile .order_summary_container {
  min-width: 325px;
  max-width: 325px;
}

.order_summary_inner_container {
  padding: 2em;
}

.order_summary_grid {
  display: grid;
  gap: 1rem;
}

.order_summary_container h3 {
  font-weight: 500;
  color: black;
  margin: 0;
  margin-bottom: 1em;
  text-transform: uppercase;
  text-align: center;
}

.order_summary_container h4 {
  font-weight: 500;
  color: black;
  margin: 0;
  margin-bottom: 1em;
  text-transform: uppercase;
  text-align: left;
}

.order_summary_container .promo_code_field {
  border: 0;
  background-image: linear-gradient(to right, #9e9e9e, #bcbcbc, #9e9e9e);
  font-size: 1.25rem;
  height: 2em;
  padding: 0 0.5em;
  font-weight: 300;
  text-transform: uppercase;
}

.promo_code_readonly {
  font-size: 1rem;
  color: black;
  font-weight: 300;
}

.order_summary_container hr {
  border: 0px;
  height: 1px;
  width: 100%;
  background: black;  
  margin: 0.2rem 0;
}

.order_summary_container .separator {
  height: 0.5em;
}

.order_summary_table {
  width: 100%;
  border-collapse: collapse;
}

.order_summary_table td {
  color: black;
  font-weight: 300;
  text-transform: uppercase;
  padding: 0.25em 0;
}

.order_summary_table td.order_summary_table_price {
  font-weight: 500;
  text-align: right;
}

.order_summary_table td.order_summary_table_price--larger {
  font-size: 1.25em;
}

.order_summary_table--strikethrough {
  text-decoration: line-through;
}

.order_summary_container .validation_result {
  margin-top: 0.25em;
}

.validation_error {
  font-weight: 300;
  color: #b00;
}

.validation_success {
  font-weight: 300;
  color: #0b0;
}

.validation_progress {
  font-weight: 300;
  color: #666;
}

.order_summary_container .promo_code_field.promo_code_field--applied {
  color: #0b0;
  -webkit-text-fill-color: #0b0;  /* necessary for Safari so that font color doesn't get faded because when the input control is disabled - https://stackoverflow.com/questions/262158/disabled-input-text-color-on-ios */
  opacity: 1;                       /* necessary for Safari so that font color doesn't get faded because when the input control is disabled - https://stackoverflow.com/questions/262158/disabled-input-text-color-on-ios */
  background-image: linear-gradient(to right, #9fcf9f, #c4e2c4, #9fcf9f);
}

.order_summary_container .promo_code_field.promo_code_field--invalid {
  color: #b00;
  background-image: linear-gradient(to right, #cf9f9f, #e2c4c4, #cf9f9f);
}

.promo_code_applied_label {
  display: inline-block;
  color: #0d0;
}

.promo_code_invalid_label {
  display: inline-block;
  color: #d00;
}

.remove_promo_code_label,
.retry_promo_code_label {
  display: none;
}

.bob_button:hover .promo_code_applied_label,
.bob_button:hover .promo_code_invalid_label {
    display: none;
}

.bob_button:hover .remove_promo_code_label,
.bob_button:hover .retry_promo_code_label {
  display: inline-block;
}
