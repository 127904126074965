.not_mobile .payment_finished_transfer_instructions {
    display: table;
    margin-inline: auto;
}

.not_mobile .payment_finished_transfer_instructions .payment_finished_transfer_instruction {
    display: table-row;
}

.not_mobile .payment_finished_transfer_instructions .payment_finished_transfer_instruction label,
.not_mobile .payment_finished_transfer_instructions .payment_finished_transfer_instruction span {
    display: table-cell;
    text-align: left;
}

.not_mobile .payment_finished_transfer_instructions .payment_finished_transfer_instruction label {
    padding-right: 2rem;
}

.mobile .payment_finished_transfer_instructions,
.mobile .payment_finished_transfer_instructions .payment_finished_transfer_instruction
.mobile .payment_finished_transfer_instructions .payment_finished_transfer_instruction label,
.mobile .payment_finished_transfer_instructions .payment_finished_transfer_instruction span {
    display: block;
}

.payment_finished_transfer_instructions .payment_finished_transfer_instruction span {
    font-weight: 500;
    padding-bottom: 1.5rem;
}
