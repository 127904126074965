.payment_and_terms_container {
    background: white;
}

.not_mobile .payment_and_terms_container {
    min-width: 800px;
    max-width: 800px;
}

.mobile .payment_and_terms_container {
    width: 100%;
}

.payment_and_terms_inner_container {
    padding: 2em;
}

.payment_and_terms_inner_container h3 {
    font-weight: 500;
    color: black;
    margin: 0;
    margin-bottom: 1em;
    text-transform: uppercase;
}

.payment_and_terms_inner_container h4 {
    font-weight: 500;
    font-size: 1rem;
    color: black;
    margin: 0;
    margin-top: 1em;
    margin-bottom: 1em;
    text-transform: uppercase;
}

.payment_and_terms_inner_container p {
    color: black;
    font-weight: 300;
    margin: 0;
}

.payment_and_terms_inner_container hr {
    border: 0px;
    height: 1px;
    background: black;
}

/* Payment method */

.payment_method {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
}

.payment_method label {
    padding: 0.5em 0;
    margin: 0.5em 1em;
    color: black;
    font-weight: 300;
    text-transform: uppercase;
    border: 2px solid transparent;
}

.mobile .payment_method label {
    padding-inline: 0;
    margin-inline: 0;
}

.payment_method i {
    text-align: center;
    padding-left: 1em;
    padding-right: 2em;
    color: black;
}

.mobile .payment_method i {
    padding-left: 0.5em;
    padding-right: 1em;
}
.payment_method:not(.payment_method--disabled) label,
.payment_method:not(.payment_method--disabled) i {
    cursor: pointer;
}

.payment_method--selected i {
    color: var(--bob-selected);
}

.payment_method--selected label > span {
    color: var(--bob-selected);
    font-weight: bold;
    border-bottom: 2px solid var(--bob-selected);
}

.payment_method--disabled i {
    color: gray;
}

.payment_method--disabled label {
    color: gray;
}

/* Terms and conditions */

.terms_and_conditions {
    padding-top: 1.5em;
}

.terms_and_conditions li {
    display: flex;
    align-items: center;
    color: black;
}

.terms_and_conditions li i {
    padding-left: 1em;
    padding-right: 2em;
}

.terms_and_conditions li label {
    font-weight: 300;
    text-transform: uppercase;
    margin-block: 0.5rem;
}

.terms_and_conditions li label a,
.terms_and_conditions li label a:hover,
.terms_and_conditions li label a:visited,
.terms_and_conditions li label a:active {
    font-weight: 500;
    color: black;
}

.terms_and_conditions li.terms_and_conditions-selected label a,
.terms_and_conditions li.terms_and_conditions-selected label a:hover,
.terms_and_conditions li.terms_and_conditions-selected label a:visited,
.terms_and_conditions li.terms_and_conditions-selected label a:active {
    font-weight: 700;
    color: #666;
    text-decoration-thickness: 2px;
}

.terms_and_conditions li label a:hover {
    text-decoration: none;
}

.terms_and_conditions li.terms_and_conditions-selected {
    color: var(--bob-selected);
}

.terms_and_conditions li.terms_and_conditions-selected label {
    font-weight: bold;
    text-decoration: underline;
    text-decoration-thickness: 2px;
}

.terms_and_conditions .terms_and_conditions--icon_with_asterisk {
    position: relative;
    display: inline-block;
}

.terms_and_conditions .terms_and_conditions--icon_with_asterisk::after {
    content: "*";
    position: absolute;
    top: -0.25em;
    right: 1.25em;
    font-size: 1.5em;
    color: red;
}

.terms_and_conditions li.terms_and_conditions-selected .terms_and_conditions--icon_with_asterisk::after {
    color: var(--bob-selected);
}