.bob_form_container {
    background: linear-gradient(to right, #e2c9a9 0%, #caa879 65%, #d5b78e 100%);
}

.not_mobile .bob_form_container {
    min-width: 800px;
    max-width: 800px;
}

.bob_form_inner_container {
    padding: 2em;
}

.bob_form .form_fixed_grid {
    display: grid;
    gap: 0.5rem 1rem;
    align-items: center;
}

.not_mobile .bob_form .form_fixed_grid {
    grid-template-columns: auto 1fr;
}

.bob_form .form_fixed_grid h3 {
    grid-column: span 2;
}

.bob_form .form_fixed_grid input {
    width: 100%;
}

.mobile .bob_form .form_fixed_grid input {
    margin-bottom: 0.5rem;
}

.bob_form .form_flexible_grid {
    display: grid;
    gap: 0.5rem 1rem;
    align-items: center;
    grid-template-columns: 1fr;
}

.bob_form .form_flexible_grid > div {
    display: flex;
    align-items: center;
    gap: 0 1em;
}

.bob_form .form_flexible_grid > div > label {
    flex: 0 0 auto;
    width: min-content;
}

.bob_form .form_flexible_grid > div > input {
    flex: 1;
}

.bob_form .form_mobile_grid > div > label {
    display: block;
    margin-top: 1rem;
}

.bob_form .form_mobile_grid > div > input {
    display: block;
    width: 100%;
    margin-top: 0.5rem;
    height: 2.75rem;
}

.bob_form h3 {
    font-weight: 500;
    color: black;
    margin: 0;
    margin-top: 2em;
    margin-bottom: 1.5em;
    text-transform: uppercase;
    font-size: 1.2rem;
}

.bob_form h3.no_top_padding {
    margin-top: 0;
}

.bob_form h3.small_bottom_padding {
    margin-bottom: 0.75rem;
}

h3 .pass_name {
    font-weight: 300;
}

.bob_form h4 {
    font-weight: 300;
    color: black;
    margin: 0;
    margin-top: 2em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
    font-size: 1.2rem;
}

.bob_form label {
    color: black;
    text-transform: uppercase;
    white-space: nowrap;
}

.bob_form input {
    color: black;
    background: white;
    white-space: nowrap;
    border: 1px solid white;
    font-size: 1.15rem;
    height: 2em;
    padding: 0 0.3em;
    font-weight: 300;
    letter-spacing: 0.1em;
}

.bob_form_bold {
    font-weight: 500;
}

.bob_form_bold  {
    font-weight: 500;
}

.bob_form input:disabled {
    background: #e0e0e0;
    border-color: #e0e0e0;
}


.hat_checkbox {
    display: flex;
    align-items: center;
}

.hat_checkbox i {
    color: black;
}

.hat_checkbox label {
    font-weight: 300;
    color: black;
    margin: 0 1em;
    padding: 0.25em 0;
}

.hat_checkbox.hat_checkbox--selected i,
.hat_checkbox.hat_checkbox--selected label {
    color: var(--bob-selected);
    font-weight: 500;
}

.hat_checkbox.hat_checkbox--selected label {
    border-bottom: 2px solid var(--bob-selected);
}


ul.hat_checkboxes-horizontal {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style-type: none;
    margin: 0;
    margin-top: 0.5em;
    padding: 0;
}

ul.hat_checkboxes-horizontal li {
    display: flex;
    flex-direction: row;
    align-items: center;
}

ul.hat_checkboxes li i {
    color: black;
}

ul.hat_checkboxes li label {
    font-weight: 300;
    color: black;
    margin: 0 1em;
    padding: 0.25em 0;
}

ul.hat_checkboxes li.hat_checkbox--selected i,
ul.hat_checkboxes li.hat_checkbox--selected label {
    color: var(--bob-selected);
    font-weight: 500;
}

ul.hat_checkboxes li.hat_checkbox--selected label > span {
    border-bottom: 2px solid var(--bob-selected);
}

ul.hat_checkboxes-vertical {
    display: block;
}

ul.hat_checkboxes-vertical li {
    margin-block: 0.25rem;
    display: grid;
    grid-template-columns: auto 1fr;
}