body {
  background: #111;
  background-image: url('/public/images/sky-background-repeatable.png');
  background-repeat: repeat-y;
  background-size: 100%;
  color: #eee;
  font-family: OswaldCustom;
  font-weight: 300;
  letter-spacing: 0.05em;

  /* BOB variables */
  --bob-gold: #cead80;
}

* {
  /* prevent padding from increasing element size: https://stackoverflow.com/questions/4767010/why-does-css-padding-increase-size-of-element */
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

input {
  border-radius: 0;
  /* prevent Safari on iOS from displaying rounded corners */
}

input:focus,
textarea:focus {
  outline: none;
  /* remove blue glow around selected input field: https://stackoverflow.com/questions/9287803/removing-the-blue-glow-from-an-html-text-input-when-selected */
}

textarea {
  border-radius: 0;
  /* turn off textarea rounding in Safari on iOS */
}

/* Remove default styling from all list elements: https://stackoverflow.com/questions/26289102/removing-default-styling-from-html-list-elements */
ul {
  list-style: none;
}

ul,
li {
  margin: 0;
  padding: 0;
}

.clickable,
.clickable * {
  cursor: pointer;
  /* prevent selecting text/images on fast repetitive clicking */
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
  touch-action: manipulation;
  /* make clicks in Safari on the iPhone faster by removing the default 300ms delay - https://stackoverflow.com/questions/12238587/eliminate-300ms-delay-on-click-events-in-mobile-safari */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  /* prevent a short gray hightlight from appearing in Safari on iPhone on click - https://stackoverflow.com/questions/8889490/ios-safari-prevent-input-field-from-highlighting-when-selected */
}

:root {
  font-size: 10pt;
  --bob-selected: #aa6c09;
}

h1,
h2 {
  font-size: 3.4rem;
  font-stretch: 50%;
  text-transform: uppercase;
  text-align: center;
  margin: 1em 0 0.5em 0;
}

.mobile {
  margin-inline: 1rem;
}

.mobile h1,
.mobile h2 {
  font-size: 2rem;
}

.no_selection {
  user-select: none;
  /* standard syntax */
  -webkit-user-select: none;
  /* webkit (safari, chrome) browsers */
  -moz-user-select: none;
  /* mozilla browsers */
  -khtml-user-select: none;
  /* webkit (konqueror) browsers */
  -ms-user-select: none;
  /* IE10+ */
}

.text_transform-uppercase {
  text-transform: uppercase;
}

.text_transform-none {
  text-transform: none !important;
}

.width_100_percent {
  width: 100%;
}

.centered_on_screen {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}

.not_mobile .min_height_container_for_connectwithus {
  min-height: 525px;
}

/* BOB BUTTON */

.bob_button {
  position: relative;

  font-family: OswaldCustom;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: 1.0em;
  text-transform: uppercase;
  padding: 0.65em 2em;
  border: none;
  cursor: pointer;
  /* no selection */
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}

.bob_button--icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
}

.bob_button--hide_content {
  opacity: 0;
}

.button_overlay {
  position: absolute;
  /* height: 100%; */
}

/* Button: SOLID_WHITE */

.bob_button.bob_button-solid_white {
  background: white;
  border: 2px solid white;
  color: black;
}

.bob_button.bob_button-solid_white:disabled {
  color: grey;
}

/* for @media hover: see https://css-tricks.com/solving-sticky-hover-states-with-media-hover-hover/ */
@media (hover: hover) {
  .bob_button.bob_button-solid_white:hover:not(:disabled) {
    background: black;
    border: 2px solid black;
    color: white;
  }
}

@media (hover: hover) {
  .bob_button.bob_button-solid_white.bob_button-solid_white--white_border:hover:not(:disabled) {
    border: 2px solid white;
  }
}

/* Button: HOLLOW_BLACK */

.bob_button.bob_button-hollow_black {
  background: transparent;
  border: 2px solid black;
  color: black;
}

@media (hover: hover) {
  .bob_button.bob_button-hollow_black:hover:not(:disabled) {
    background: black;
    border: 2px solid black;
    color: white;
  }
}

.bob_button.bob_button-hollow_white {
  background: transparent;
  border: 2px solid white;
  color: white;
}

.bob_button.bob_button-hollow_white.bob_button-busy {
  background: white;
  border: 2px solid white;
  color: black;
}

/* Button: HOLLOW_WHITE */

@media (hover: hover) {
  .bob_button.bob_button-hollow_white:hover:not(:disabled) {
    background: white;
    border: 2px solid white;
    color: black;
  }
}

/* Button: SOLID_BLACK */

.bob_button.bob_button-solid_black {
  background: black;
  border: 2px solid black;
  color: white;
}

@media (hover: hover) {
  .bob_button.bob_button-solid_black:hover:not(:disabled) {
    border: 2px solid black;
    background: transparent;
    color: black;
  }
}

/* Button: TRANSPARENT_WHITE */

.bob_button.bob_button-transparent_white {
  color: black;
  background: transparent;
  border: 2px solid white;
}

@media (hover: hover) {
  .bob_button.bob_button-transparent_white:hover:not(:disabled) {
    background: black;
    border: 2px solid black;
    color: white;
  }
}

/* Button: SOLID_GOLD */

.bob_button.bob_button-solid_gold {
  color: white;
  background: var(--bob-gold);
  border: 2px solid var(--bob-gold);
  font-weight: 500;
  letter-spacing: 1px;
}

@media (hover: hover) {
  .bob_button.bob_button-solid_gold:hover:not(:disabled) {
    background: black;
    border: 2px solid black;
    color: white;
  }
}



/* BOB REACT-SELECT */

.bob_react_select .react-select__menu {
  font-weight: 300;
  color: black;
}

.bob_react_select .react-select__control {
  border: 0;
  border-radius: 0;
  min-height: 0;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: 1.15rem;
}

.bob_react_select .react-select__value-container {
  padding: 0;
  padding-left: 0.25em;
}

.bob_react_select .react-select__single-value {
  color: black;
}

.bob_react_select .react-select__input-container {
  padding: 0;
  margin: 0;
}

.bob_react_select .react-select__control--is-focused {
  border: 0;
  box-shadow: none;
}

.bob_react_select .react-select__control--menu-is-open {
  border: 0;
}

.bob_react_select .react-select__option.react-select__option--is-focused {
  background: #dec4a1;
}

.bob_react_select .react-select__placeholder {
  display: none;
}

.bob_react_select .react-select__dropdown-indicator {
  padding: 6px !important;
}

/* FORMS */

input {
  font-family: OswaldCustom;
  font-weight: 500;
  letter-spacing: 0.05em;
  font-size: 1.0em;
}

/* LINKS */

a.white_link,
a.white_link:hover,
a.white_link:visited,
a.white_link:active {
  color: white;
}

/* FONTS */

@font-face {
  font-family: OswaldCustom;
  src: url('/public/fonts/Oswald-Bold-Stretched-110-90.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: OswaldCustom;
  src: url('/public/fonts/Oswald-Medium-110-90.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: OswaldCustom;
  src: url('/public/fonts/Oswald-Light-110-90.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
