.info_textarea {
  width: 100%;
  /* height: 100px; */
  border: 0;
  min-height: 50px;
  resize: vertical;

  font-family: OswaldCustom;
  font-weight: 300;
  font-size: 1.2rem;
  letter-spacing: 0.1em;

  padding: 0.5rem 0.5rem;

}