.current_step_container {
  margin-top: 4rem;
  margin-bottom: 4rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
}

.mobile .current_step_container {
  margin-top: 2rem;
}

.current_step_steps {
  padding-top: 1rem;
  font-size: 1.2rem;
}

.current_step_steps span:not(.current_step_step--active) {
  color: #999;
}

.current_step_step,
.current_step_step:hover,
.current_step_step:visited,
.current_step_step:active
{
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}

.current_step_step--active,
.current_step_step--active:hover,
.current_step_step--active:visited,
.current_step_step--active:active
{
  color: #fff;
  font-weight: 700;
}

.current_step_separator {
  padding-inline: 1rem;
}