.cart_table {
  width: 100%;
  border-collapse: collapse;
}

.cart_table td {
  color: black;
}

.cart_table .cart_item > td {
  font-weight: 300;
}

.cart_table .cart_total > td {
  font-weight: 500;
}

.cart_table .cart_separator hr {
  width: 100%;
  border: 0;
  height: 1px;
  background: black;
  margin: 1em 0;
}

.cart_table .cart_name {
  width: 70%;
  text-align: left;
}

.cart_table .cart_amount {
  width: 15%;
  text-align: right;
}

.cart_table .cart_buttons {
  width: 15%;
  text-align: right;
  padding: 0 1em;
}

.cart_table .cart_buttons .button {
  padding: 0 1em;
  cursor: pointer;
}

.cart_table .cart_buttons .cart_button {
  font-weight: 700;
}

@media (hover: hover) {
  .cart_table .cart_buttons .button:hover {
    color: white;
  }
}

.cart_table .cart_empty_message {
  text-align: center;
}

.cart_trash_icon {
  width: 1.5em;
  height: 1.5em;
  padding-left: 1em;
}

.cart_item_unavailable {
  color: #cc0000;
  font-weight: 500;
}