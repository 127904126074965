.currency_container {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding-right: 0.25rem;
}

/* .currency_container__currency {
} */

.currency_container__currency--selected {
  color: var(--bob-gold);
}

.currency_container__separator {
  padding-inline: 0.5rem;
}