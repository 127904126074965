.header_container {
    padding: 3em 3em 1em 3em;
}

.mobile .header_container {
    padding: 1em 0 1em 0;
}

.header_table {
    display: table;
    white-space: nowrap;
    width: 100%;
}

.header_item {
    display: table-cell;
    vertical-align: middle;
    font-weight: 300;
    font-size: 1rem;
    line-height: 2em;
    padding: 1em;
    width: 1%;
}

.header_item.header_item-remaining {
    overflow: hidden;
    width: 99%;
}

.header_separator {
    width: 1px;
    height: 2em;
    background: white;
}

.header_item a, 
.header_item a:visited, 
.header_item a:active, 
.header_item a:hover {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
}

.logo_icon {
    width: 1.5em;
    height: 1.5em;
    color: white;
    position: relative;
    top: 0.25em;
}
