.checkout_page_container {
  display: grid;  
  align-items: center;
  grid-template-columns: 1fr;
  margin-top: 2em;
  margin-bottom: 4em;
  margin-inline: auto;
}

.not_mobile .checkout_page_grid {
  margin-inline: auto;
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
}

.checkout_page_left_grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.checkout_page_right_grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.mobile .checkout_page_right_grid {
  margin-top: 2rem;
}

.checkout_page_sticky {
  position: sticky;
  top: 0px;
  height: 100px;    /* this is a weird setting but sticky needs a height */
}
