
.pass_registration_container .more_padding_on_top {
  padding-top: 1em;
}

.mobile .pass_registration_container .more_padding_on_top {
  display: block;
  padding-top: 2em;
}

.more_padding_at_bottom {
  padding-bottom: 1rem;
}
