.buy_pass_cart_container {
  background: linear-gradient(to right, #e2c9a9 0%, #caa879 50%, #d5b78e 100%);
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  margin-bottom: 4em;
}

.not_mobile .buy_pass_cart_container {
  min-width: 800px;
  max-width: 1100px;
}

/* .mobile .buy_pass_cart_container {
  min-width: unset;
  max-width: unset;
} */

.buy_pass_cart_table_container {
  padding: 2em;
}

.continue_button_container {
  text-align: center;
  margin: 3em 0 1em 0;
}

.mobile .continue_button_container {
  margin: 2em 0 0 0;
}

.buy_pass_cart_container .cart_table .cart_item > td {
  font-size: 1.2rem;
}

.buy_pass_cart_container .cart_table .cart_total > td {
  font-size: 1.4rem;
}
