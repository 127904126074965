.payment_finished_page_container {
  text-align: center;
}

.payment_finished_page_container p,
.payment_finished_page_container .payment_finished_page_paragraph {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 6rem;
  line-height: 3rem;

  margin-left: 4rem;
  margin-right: 4rem;
}

.mobile .payment_finished_page_container p {
  margin-bottom: 3rem;
}

.payment_finished_page_container img {
  width: 150px;
}

.payment_finished_page_paragraph-secondary {
  margin-top: 0;
  margin-bottom: 1rem !important;
  padding-top: 0;
  padding-bottom: 0;
}

.payment_finished_page_separator {
  height: 3rem;
}

.payment_finished_page_button_separator {
  display: inline-block;
  width: 3rem;
}

.payment_finished_page_container table {
  margin-inline: auto;
}

.payment_finished_page_container table td {
  text-align: left;
}

.payment_finished_page_container table td:nth-child(2) {
  padding-left: 2rem;
}

.not_mobile .payment_finished_page_container .payment_finished_page_container_buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.mobile .payment_finished_page_container .payment_finished_page_container_buttons {
  display: grid;
  gap: 2rem;
}

.mobile .payment_finished_page_container .payment_finished_page_container_buttons > * {
  display: block;
  margin-inline: auto;
}

.payment_finished_page_container {
  padding-bottom: 5rem;
}
